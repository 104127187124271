<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import customTable from "@/components/paginate-table.vue";
import axios from "axios";
import mobileView from "../../../components/mobile-view.vue";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, customTable, mobileView },
  page: {
    title: "Penjualan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "#/",
        },
        {
          text: "Order",
          active: true,
        },
      ],
      apiUrl: process.env.VUE_APP_API_BASE_URL + "orders",
      apiCategory: process.env.VUE_APP_API_BASE_URL + "customer-categories",
      dataKey: "stock",
      data: {
        prop: "referral",
        label: "Referral",
        sortable: true,
        center: true,
        width: 150,
      },
      params: [
        {
          'name': 'include_omset_profit',
          'value': 1
        }
      ],
      screenWidth: 0,
      category: [],
      categoryName: [],
      columns: [
        {
          prop: "created_at",
          label: "Tgl",
          center: false,
          width: 200,
          date: true,
        },
        {
          prop: "order_number",
          label: "No. Order",
          center: true,
          width: 200,
        },
        {
          prop: "customer.name",
          label: "Pelanggan",
          center: true,
          width: 150,
        },
        {
          prop: "customer.customer_category.name",
          label: "Kategori Pelanggan",
          center: true,
          width: 150,
        },
        {
          prop: "status",
          label: "Status",
          center: true,
          width: 150,
          badgeOrder: true,
        },
        {
          prop: "amount_down_payment",
          label: "DP",
          right: true,
          width: 150,
          dp: true,
        },
        {
          prop: "grand_total",
          label: "Sisa Tagihan",
          right: true,
          width: 150,
          totalOfPayment: true,
        },
        {
          prop: "total",
          label: "Total Belanja",
          right: true,
          width: 150,
          total: true,
        },
        {
          prop: "created_by.name",
          label: "Dibuat Oleh",
          user: true,
          center: true,
          width: 150,
        },
        {
          prop: "updated_by.name",
          label: "Diupdate Oleh",
          user: true,
          center: true,
          width: 150,
        },
      ],
    };
  },
  methods: {
    async getCategory() {
      try {
        this.isLoading = true;
        await axios.get(this.apiCategory).then((response) => {
          const uniqueRoles = [
            ...new Set(
              response.data.data.map((data) => {
                return data;
              })
            ),
          ];

          this.categoryName = uniqueRoles.map((role) => ({
            name: role.name,
            value: role.name,
            id: role.id,
          }));
        });
      } catch (error) {
        console.error(error);
      }
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
    this.getCategory();
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout v-if="screenWidth >= 860">
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <custom-table
          ref="dataTable"
          :with-add="false"
          :with-form-add="false"
          :with-revenue-order="true"
          :form-url="'/penjualan/tambah'"
          :custom-detail-url="'/penjualan/'"
          :default-params="params"
          :with-filter-name="true"
          :with-fixed="true"
          :with-delete="false"
          :with-print="false"
          :with-edit="false"
          :data-url="apiUrl"
          :data-key="dataKey"
          :data-columns="columns"
          :merge="data"
          :action="true"
          :with-filter-category-order-down="true"
          :with-filter-created-by-url="true"
          :with-filter-date="true"
          :with-filter-order="true"
          :select-options="categoryName"
          title-filter="Filter Berdasarkan Nama Pelanggan"
          filter-with="customer_name"
        ></custom-table>
      </b-card-body>
    </b-card>
  </Layout>
  <mobileView
    v-else
    ref="dataTable"
    title-filter="Filter Berdasarkan Nama Pelanggan"
    :with-filter-text="true"
    :with-filter-date="true"
    :with-filter-order="true"
    :with-filter-created="true"
    :with-filter-category="true"
    :with-delete="false"
    :select-options="categoryName"
    :form-url="'/penjualan/tambah'"
    :data-url="apiUrl"
    skeleton-radius="5px"
    :card-default="false"
    :card-order="true"
    filter-with="customer_name"
  ></mobileView>
</template>

<style scoped>
.badge-custom {
  display: inline-block;
  padding: 0.4em 0.8em;
  font-size: 80%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
}
</style>
